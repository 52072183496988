import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageComponent } from './components/shares/message/message.component';
import { LocalStorageEnum } from './models/enums/local-storage.enum';
import { LoadingService } from './services/loading.service';
import { LocalStorageService } from './services/local-storage.service';
import { RequestService } from './services/request.service';
import { UtilService } from './services/util.service';
import { AuthService } from './services/auth.service'
import { GuardsCheckStart, NavigationCancel, NavigationEnd, NavigationSkipped, NavigationStart, Router } from '@angular/router';
import { Subscription, delay, lastValueFrom, timer } from 'rxjs';
import { SingleSignOnService } from './services/single-sign-on.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';
import { RESPONSE_STATUS } from './models/enums/response-status.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nssf-benefit-admin';
  isLoading = false;
  lang: string = '';

  current_time!: number;
  expired_time!: number;
  load_expired_dialog_time!: number;
  decode: any;
  token!: string;

  timer: any;

  private iconPath: string = "/assets/icons/";
  private iconExt: string = '.svg';

  isSetPreviouse?: boolean;
  tokenExpireSubscription!: Subscription;

  public userAuthenticated = false;
  IS_ENABLE_OAUTH: boolean = environment.IS_ENABLE_OAUTH;

  constructor(
    private loadingService: LoadingService,
    private localStorageService: LocalStorageService,
    private icon: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private util: UtilService,
    private dialog: MatDialog,
    private requestService: RequestService,
    private router: Router,
    private singleSignOnService: SingleSignOnService,
    private authService: AuthService,
    private _ngxSpinnerService: NgxSpinnerService,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    let current_token: string = this.localStorageService.get(LocalStorageEnum.token);
    if (this.IS_ENABLE_OAUTH && !this.singleSignOnService.isLoggedIn && current_token) {
      this.authService.logout().subscribe((res: any) => {
        if (res.status === 1) {
          this.authService.logOutEventEmitter.emit(true);
          this.requestService.onSignOut();
        }
      });
    };
  }

  ngOnInit() {
    this.util.initializeTranslate();
    this.addIcons();

    this.loadingService.loadingEventEmitter.pipe(delay(0)).subscribe((isLoading: boolean) => {
      if (isLoading) {
        this._ngxSpinnerService.show("pageLoading");
      } else {
        this._ngxSpinnerService.hide("pageLoading");
      }
    });

    if (this.authService.isAuthorized) {
      this.onDefault();
    }

    this.authService.logInEventEmitter.subscribe(res => {
      if (res) {
        this.onDefault();
      }
    })

    this.authService.logOutEventEmitter.subscribe(res => {
      if (res) {
        this.tokenExpireSubscription.unsubscribe();
        this.dialog.closeAll();
      }
    });

    this.router.events.subscribe((event): void => {
      if (event instanceof NavigationStart) {
        this.isLoading = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof GuardsCheckStart || event instanceof NavigationSkipped) {
        this.isLoading = false;
      }
    });
  }

  private async _getNewToken() {
    const response = await lastValueFrom(this.authService.getNewToken());
    if (response.status == RESPONSE_STATUS.SUCCESS) {
      this.onDefault();
    }

    /**
     * Refresh token single sign on
     */
    if (this.IS_ENABLE_OAUTH) {
      this.singleSignOnService.onRefreshToken();
    }
  }

  onDefault() {
    const expireTokenTime = parseInt(this.localStorageService.get(LocalStorageEnum.expiry_time)) * 1000; // * 1000 meant convert it to milisecond
    if (!expireTokenTime) {
      this.requestService.onSignOut();
      return;
    }
    const preAlertTime = expireTokenTime - (15 * 60 * 1000);
    const timeToAlertExpiredTokenDialog = preAlertTime - Date.now(); // equal to before 15mn being expired token // 900000 is 15mn in milisecond

    if ((timeToAlertExpiredTokenDialog + preAlertTime) < 1) {
      this.requestService.onSignOut();
      return; 
    }
    this.tokenExpireSubscription = timer(timeToAlertExpiredTokenDialog)
      .subscribe(() => {
        this.dialog.open(MessageComponent, {
          width: "500px",
          disableClose: true,
          autoFocus: false,
          data: {
            is_expired_token: true
          }
        }).afterClosed().subscribe(async response => {
          if (response) {
            await this._getNewToken();
            return;
          }
          this.requestService.onSignOut();
        });
      });
  }

  addIcons() {
    this.addIcon('mail', 'mail');
    this.addIcon('pass', 'lock');
    this.addIcon('next', 'next');
    this.addIcon('next_step', 'next_step');
    this.addIcon("menu", "menu-toggle");
    this.addIcon("en", "gb");
    this.addIcon("km", "kh");
    this.addIcon("notification", "notification");
    this.addIcon('news', 'news');
    this.addIcon('news_color', 'news_color');
    this.addIcon('invalid', 'invalid');
    this.addIcon('valid', 'valid');
    this.addIcon('valid_color', 'valid_color');
    this.addIcon('invalid_color', 'invalid_color');
    this.addIcon('valid_status_color', 'valid_status_color');
    this.addIcon('invalid_status_color', 'invalid_status_color');
    this.addIcon('not_found', 'not_found');
    this.addIcon('not_found_status_color', 'not_found_status_color');
    this.addIcon('not_found_color', 'not_found_color');
    this.addIcon('invoice', 'invoice');
    this.addIcon("request", "request");
    this.addIcon("members", "members");
    this.addIcon("members_color", "members_color");
    this.addIcon("request_color", "request_color");
    this.addIcon("pending", "pending");
    this.addIcon("reject", "reject");
    this.addIcon("active", "active");
    this.addIcon('tracking_time', 'tracking_time');
    this.addIcon('tracking_time_color', 'tracking_time_color');
    this.addIcon('person', 'person');
    this.addIcon('person_color', 'person_color');
    this.addIcon("upload", "upload");
    this.addIcon('folder_create', 'folder_create');
    this.addIcon('cloud', 'cloud');
    this.addIcon('upload_data', 'upload_data');
    this.addIcon('submit', 'submit');
    this.addIcon('upload_invoice', 'upload_invoice');
    this.addIcon('allocate', 'allocate');
    this.addIcon('info', 'info');
    this.addIcon('info_color', 'info_color');
    this.addIcon('penalty', 'penalty');
    this.addIcon('penalty_color', 'penalty_color');
    this.addIcon('history', 'history');
    this.addIcon('history_color', 'history_color');
    this.addIcon("contract", "contract");
    this.addIcon("contract_color", "contract_color");
    this.addIcon('excel', 'excel');
    this.addIcon('empty_list', 'empty_list');
    this.addIcon('file_search', 'file_search');
    this.addIcon('invoice_no_color', 'invoice_no_color')
  }

  private addIcon(name: string, filename: string): void {
    this.icon.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(this.iconPath + filename + this.iconExt));
  }
}

