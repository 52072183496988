export enum FileType {
    HEALTHCARE = "HEALTHCARE",
    WORKINJURY = "WORKINJURY",
    CLAIM_REQ = "BENEFITCLAIMREQUEST",
    BENEFIT_TEMP_CLAIM_REQ = "BENEFIT_TEMP_CLAIM_REQ"
}

export enum FileExtension {
    PDF = "application/pdf",
    IMAGE_JPG = "image/jpeg",
    IMAGE_PNG = "image/png",
    ZIPPED_FILE = ".zip",
    COMPRESSED_FILE = "application/x-zip-compressed"
}